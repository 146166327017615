$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;


@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,800&display=swap");
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";

@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all.sass";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@creativebulma/bulma-divider";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import '~swiper/swiper-bundle';
@import '../node_modules/angular-archwizard/styles/archwizard.scss';
@import '~@creativebulma/bulma-collapsible';



*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  body {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;

    .app {
      width: 100%;
    }

    .navbar-item img {
      max-height: 3rem;;
    }
  }
  
}

.icon-red i {
  color:red;
}

.icon-green i {
  color:green;
}

.datetimepicker-dummy .datetimepicker-dummy-wrapper, .file-cta, .file-name, .input, .select select, .textarea {
  height: 2.5em !important;
}

.fake-disabled {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5  !important;
    box-shadow: none  !important;
    color: #7a7a7a  !important;
}
